import React, { useEffect } from "react";
import gsap from "gsap";
import styles from "./ContentSection.module.scss";
import cn from "utils/classNames";
import dotsLeft from "assets/svgs/dots-rectangle-left.svg";
import dotsRight from "assets/svgs/dots-rectangle-right.svg";
import ContentSectionTitle from "./ContentSectionTitle";

type Props = {
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  hasDiagonalDots?: boolean;
};

const ContentSection: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  title = "",
  subtitle = null,
  hasDiagonalDots = false,
  className,
  children,
}) => {
  useEffect(() => {
    gsap.to(".topLeftDots", {
      scrollTrigger: {
        trigger: ".contentContainer",
        start: "-50% 30%",
        end: "150%",
        scrub: 1,
      },
      y: -90,
      x: -40,
      opacity: 0.3,
    });
    gsap.to(".bottomRightDots", {
      scrollTrigger: {
        trigger: ".contentContainer",
        start: "0% 30%",
        end: "120%",
        scrub: 1,
      },
      y: 80,
    });
  }, []);

  return (
    <div className={cn(styles.contentSection, className)}>
      <ContentSectionTitle className={styles.contentSectionTitle} title={title} subtitle={subtitle} />
      <div className={cn("contentContainer", styles.contentSectionContainer)}>
        <div className={styles.childrenContainer}>
          {hasDiagonalDots && (
            <img src={dotsLeft} alt="" className={cn("topLeftDots", styles.dots, styles.dotsTopLeft)} />
          )}
          {children}
          {hasDiagonalDots && (
            <img src={dotsRight} alt="" className={cn("bottomRightDots", styles.dots, styles.dotsBottomRight)} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentSection;
