import React from "react";
import cn from "utils/classNames";
import styles from "./ContentSectionTitle.module.scss";

export enum TitleVariant {
  Headline,
  Normal,
}

type Props = {
  titleVariant?: TitleVariant;
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
};

const ContentSectionTitle: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  titleVariant = TitleVariant.Normal,
  title = "",
  subtitle = null,
  className,
  id,
}) => {
  return (
    <div className={cn(styles.elementContainer, className)} id={id}>
      <div className={cn(styles.title, { [styles.titleHeadline]: titleVariant === TitleVariant.Headline })}>
        {title}
      </div>
      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
    </div>
  );
};

export default ContentSectionTitle;
